<template>
  <div v-loading="isSubmitting">
    <responsive-container :lg="12" :xl="12">
      <page-header :title="__('Import Options')"></page-header>
      <el-form
        label-position="top"
        ref="configTaskCreateForm"
        label-width="100px"
        class="import-options-form-container"
        :model="configuration"
        :rules="rules"
      >
        <el-form-item
          :label="__('Prepend text to imported content item names?')"
          prop="new_content_suffix"
        >
          <el-input
            :placeholder="newContentSuffixPlaceHolderText"
            v-model="configuration.new_content_suffix"
          ></el-input>
          <div class="sub-script-info">{{ newContentPrefixWarningText }}</div>
        </el-form-item>

        <el-form-item
          class="manage-data-stores"
          :label="__('How do you want to manage Datastores?')"
          prop="import_config.dataStores"
          v-if="loadConfig.has_data_stores"
          required
        >
          <div v-if="emptyDataDump" class="info" style="margin-bottom: 10px;">
            <div class="status-info" />
            <div style="margin-left: 5px; display: flex">
              <div>
                {{ getNoDataDumpWarning }}
              </div>
            </div>
          </div>
          <div v-else class="info" style="margin-bottom: 10px;">
            <div
              style="margin-left: 5px; display: flex; flex-direction: column"
            >
              {{ __("The following Datastores have data attached to them.") }}
              <div
                style="display: flex; flex-wrap: wrap; margin-top: 5px; margin-left: -5px;"
              >
                <el-tag
                  class="datastore-name-tag"
                  :key="index"
                  v-for="(dataStore, index) in dataStoresDumpedWithData"
                >
                  {{ dataStore }}
                </el-tag>
              </div>
            </div>
          </div>
          <el-select
            v-model="configuration.import_config.dataStores"
            :placeholder="__('Select')"
            default-first-option
            class="flex"
          >
            <el-option
              v-for="item in validDatastoreOptions"
              :key="item.value"
              :label="item.label(createType, emptyDataDump)"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          class="manage-prompts"
          :label="__('How do you want to manage Prompt?')"
          prop="import_config.reusePromptsWithSameName"
          v-if="showManagePromptsOption"
          required
        >
          <el-select
            v-model="configuration.import_config.reusePromptsWithSameName"
            :placeholder="__('Select')"
            class="flex"
          >
            <el-option
              v-for="item in reusePromptOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          v-if="hasToConfigureSecureVariablesDuringImport"
          class="manage-secure-variables"
          :label="__('How do you want to manage secure variables?')"
          prop="import_config.secureVariables"
          required
        >
          <div
            v-if="loadConfig.locked_secure_variables"
            class="info"
            style="margin-bottom: 10px;"
          >
            <div class="status-info" />
            <div style="margin-left: 5px; display: flex">
              <div>
                {{ getNoSecureVariableValueWarning }}
              </div>
            </div>
          </div>
          <el-select
            v-model="configuration.import_config.secureVariables"
            :placeholder="__('Select')"
            default-first-option
            class="flex"
          >
            <el-option
              v-for="item in validSecureVariableOptions"
              :key="item.value"
              :label="item.label(createType)"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          class="replace-task-with"
          :label="__('Remap link nodes to tasks with newly created task')"
          prop="import_config.replaceLinkedTasks"
          v-if="createType !== 'version' && hasTargetTasks"
        >
          <div slot="label">
            <span>
              {{ getLabelForReplacementTask }}
            </span>
          </div>
          <el-select
            v-model="configuration.import_config.replaceLinkedTasks"
            :placeholder="__('Select Task')"
            class="flex"
            default-first-option
            multiple
            filterable
            clearable
          >
            <el-option
              v-for="task in targetTasks"
              :key="task.task_id"
              :label="task.task_name"
              :value="task.task_id"
            >
            </el-option>
          </el-select>
          <div class="sub-script-info">{{ getReplaceLinkTaskWarning }}</div>
        </el-form-item>

        <div style="margin: 20px 0" v-if="replacementsRequired">
          <h3 style="margin: 0">
            {{ getAdditionalOptionsHeading }}
          </h3>

          <el-form-item
            v-if="!empty(ttsVoicesRequiringReplacements)"
            prop="replacement_mapper.tts_voices"
          >
            <task-import-additional-option-item
              :items="ttsVoicesRequiringReplacements"
              :item-config="{
                keyProp: 'tts_voice_id',
                valueProp: 'tts_voice_id',
                labelProp: 'voice_name',
                heading: 'TTS Voices',
                title: 'TTS Voice'
              }"
              :error-fn-config="{ fn: emptyMap, type: 'voices' }"
              :error-msg="__('Replacement Required')"
              :value="configuration.replacement_mapper.voices"
              has-option-group
              :line-item-options-config="{
                prop: 'replacement_options'
              }"
              :line-item-option-config="{
                value: 'tts_voice_id',
                label: 'voice_name',
                title: 'TTS Voice',
                placeholder: 'Select Voice'
              }"
            />
          </el-form-item>

          <el-form-item
            v-if="!empty(asrLanguagesRequiringReplacements)"
            prop="replacement_mapper.asr_languages"
          >
            <task-import-additional-option-item
              :items="asrLanguagesRequiringReplacements"
              :item-config="{
                keyProp: 'language_id',
                valueProp: 'language_id',
                labelProp: 'language_name',
                heading: 'ASR Languages',
                title: 'ASR Language'
              }"
              :error-fn-config="{ fn: emptyMap, type: 'asr_languages' }"
              :error-msg="__('Replacement Required')"
              :value="configuration.replacement_mapper.asr_languages"
              :has-option-group="false"
              :line-item-options-config="{
                prop: 'replacement_options'
              }"
              :line-item-option-config="{
                value: 'language_id',
                label: 'language_name',
                title: 'ASR Language',
                placeholder: 'Select Language'
              }"
            />
          </el-form-item>

          <el-form-item v-if="!empty(linkNodesRequiringNewTargets)">
            <task-import-additional-option-item
              :items="linkNodesRequiringNewTargets"
              :item-config="{
                keyProp: 'node_id',
                valueProp: 'node_id',
                labelProp: 'node_name',
                heading: 'Link Task Nodes',
                title: 'Link Task Node'
              }"
              :value="configuration.replacement_mapper.link_nodes"
              :has-option-group="false"
              :line-item-options-config="{
                prop: 'replacement_options'
              }"
              :line-item-option-config="{
                value: 'task_id',
                label: 'task_name',
                title: 'Task',
                placeholder: 'Select Task',
                info: linkNodeSelectorPlaceholder
              }"
              clearable
            />
          </el-form-item>

          <el-form-item v-if="!empty(authProfilesRequiringNewMapping)">
            <task-import-additional-option-item
              :items="authProfilesRequiringNewMapping"
              :item-config="{
                keyProp: 'auth_profile_id',
                valueProp: 'auth_profile_id',
                labelProp: 'auth_profile_name',
                heading: 'Authentications',
                title: 'Authentication'
              }"
              :value="configuration.replacement_mapper.auth_profiles"
              has-option-group
              :line-item-options-config="{
                prop: 'replacement_options'
              }"
              :line-item-option-config="{
                value: 'auth_profile_id',
                label: 'auth_profile_name',
                title: 'Authentication',
                placeholder: 'Select Authentication',
                info: authProfileSelectorPlaceholder
              }"
              clearable
            >
              <template
                #lineItem="{item, itemLabel, itemValue, lineItemPlaceholder, lineItemTitle, lineItemInfo, value, errorFnConfig, errorMsg, hasOptionGroup, lineItemOptionsConfig, lineItemOptionConfig, clearable}"
              >
                <task-import-additional-option-line-item
                  :item="item"
                  :item-label="itemLabel"
                  :item-value="itemValue"
                  :line-item-placeholder="lineItemPlaceholder"
                  :line-item-title="lineItemTitle"
                  :line-item-info="lineItemInfo"
                  :value="value"
                  :error-fn-config="errorFnConfig"
                  :error-msg="errorMsg"
                  :has-option-group="hasOptionGroup"
                  :line-item-options-config="lineItemOptionsConfig"
                  :line-item-option-config="lineItemOptionConfig"
                  :clearable="clearable"
                >
                  <template
                    #groupItem="{item, lineItemOptionsConfig, lineItemOptionConfig}"
                  >
                    <el-option-group
                      v-for="(groupItems, groupName) in item[
                        lineItemOptionsConfig.prop
                      ]"
                      :key="groupName"
                      :label="groupName"
                      class="auth-profile-options"
                    >
                      <el-option
                        v-for="lineItem in groupItems"
                        :key="lineItem[lineItemOptionConfig.value]"
                        :label="lineItem[lineItemOptionConfig.label]"
                        :value="lineItem[lineItemOptionConfig.value]"
                      >
                        <div
                          style="display:flex;justify-content: space-between;align-items: center"
                        >
                          <div>{{ lineItem[lineItemOptionConfig.label] }}</div>
                          <div>
                            {{ __("status") }}:
                            <span :class="lineItem.status">{{
                              lineItem.status
                            }}</span>
                          </div>
                        </div>
                      </el-option>
                    </el-option-group>
                  </template>
                </task-import-additional-option-line-item>
              </template>
            </task-import-additional-option-item>
          </el-form-item>
          <el-form-item
            v-if="!empty(knowledgeGroupRequiresNewMapping)"
            prop="replacement_mapper.knowledge_groups"
          >
            <task-import-additional-option-item
              :items="knowledgeGroupRequiresNewMapping"
              :item-config="knowledgeGroupItemConfig"
              :error-fn-config="knowledgeGroupErrorFnConfig"
              :error-msg="__('Replacement Required')"
              :value="configuration.replacement_mapper.knowledge_groups"
              :has-option-group="false"
              :line-item-options-config="replacementOptions"
              :line-item-option-config="knowledgeGroupLineItemOptionConfig"
            />
          </el-form-item>
        </div>
      </el-form>
      <el-button v-if="!isVersionSwitch" class="submitBtn" @click="submitForm()"
        >{{ __("Go To Callflow") }}
      </el-button>
      <el-button
        v-else
        class="submitBtn"
        @click="$emit('configured', configuration)"
      >
        {{ __("Switch to version") }}
      </el-button>
      <el-button class="cancelBtn" @click="$emit('cancel')">{{
        __("Cancel")
      }}</el-button>
    </responsive-container>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import _ from "lodash";
import { mapActions, mapState } from "vuex";
import ResponsiveContainer from "@/components/ResponsiveContainer";
import TaskImportAdditionalOptionItem from "@/views/build/tasks/create-task/components/TaskImportAdditionalOptionItem";
import TaskImportAdditionalOptionLineItem from "@/views/build/tasks/create-task/components/TaskImportAdditionalOptionLineItem";

export default {
  name: "ConfigureTaskCreateOptions",
  components: {
    TaskImportAdditionalOptionItem,
    TaskImportAdditionalOptionLineItem,
    ResponsiveContainer,
    PageHeader
  },
  data() {
    const dataStoreValidator = (rule, value, cb) => {
      if (!_.get(this.loadConfig, "has_data_stores", false)) {
        cb();
      } else if (!value) {
        cb(__("Select an option to manage Datastores"));
      } else {
        cb();
      }
    };

    const secureVariableValidator = (rule, value, cb) => {
      if (!_.get(this.loadConfig, "has_secure_variables", false)) {
        cb();
      } else if (!value) {
        cb(__("Select an option to manage secure variables"));
      } else {
        cb();
      }
    };

    const asrLanguageValidator = (rule, value, cb) => {
      let isSomeReplacementOptionsEmpty = _.some(
        this.asrLanguagesRequiringReplacements,
        asrLanguage => _.isEmpty(asrLanguage.replacement_options)
      );

      if (isSomeReplacementOptionsEmpty) {
        cb(__("Assign compatible ASR languages in your business plan"));
      } else {
        cb();
      }
    };

    const ttsVoicesValidator = (rule, value, cb) => {
      let isSomeReplacementOptionsEmpty = _.some(
        this.ttsVoicesRequiringReplacements,
        ttsVoice => _.isEmpty(ttsVoice.replacement_options)
      );

      if (isSomeReplacementOptionsEmpty) {
        cb(__("Assign compatible TTS Voices in your business plan"));
      } else {
        cb();
      }
    };
    return {
      configuration: {
        new_content_suffix: "",
        import_config: {
          reusePromptsWithSameName: true
        },
        replacement_mapper: {
          voices: {},
          asr_languages: {},
          link_nodes: {},
          auth_profiles: {},
          knowledge_groups: {}
        }
      },
      isSubmitting: false,
      rules: {
        "import_config.dataStores": [
          {
            validator: dataStoreValidator,
            trigger: "change"
          }
        ],
        "import_config.reusePromptsWithSameName": [
          {
            required: true,
            message: __("Select an option to manage prompts"),
            trigger: "change"
          }
        ],
        "import_config.secureVariables": [
          {
            validator: secureVariableValidator,
            trigger: "change"
          }
        ],
        "replacement_mapper.asr_languages": [
          {
            validator: asrLanguageValidator,
            trigger: "change"
          }
        ],
        "replacement_mapper.tts_voices": [
          {
            validator: ttsVoicesValidator,
            trigger: "change"
          }
        ]
      },
      dataStoreOptions: [
        {
          label: function(createType) {
            /* eslint-disable */
            switch (createType) {
              case "import":
                return __("Use a matching Datastore (if available) and replace data from imported Task");
              case "template":
                return __("Use a matching Datastore (if available) and replace data from Task template");
              case "version":
                return __("Use a matching Datastore (if available) and replace data from Task version");
              default:
                return __("Use a matching Datastore (if available) and replace data");
            }
            /* eslint-enable */
          },
          value: "REUSE_DATASTORE_REPLACE_DATA",
          disabled: function(emptyDataDump) {
            return emptyDataDump;
          }
        },
        {
          label: function(createType, emptyDataDump) {
            let defaultPreText = emptyDataDump
              ? __("Use a matching Datastore (if available)")
              : __("Use a matching Datastore (if available) but ignore data");
            /* eslint-disable */
            switch (createType) {
              case "import":
                return __(":defaultPreText from imported Task", { defaultPreText });
              case "template":
                return __(":defaultPreText from Task template", { defaultPreText });
              case "version":
                return __(":defaultPreText from Task version", { defaultPreText });
              default:
                return defaultPreText;
            }
            /* eslint-enable */
          },
          value: "REUSE_DATASTORE_REUSE_DATA",
          disabled: function() {
            return false;
          }
        },
        {
          label: function(createType) {
            /* eslint-disable */
            switch (createType) {
              case "import":
                return __("Create a new Datastore and load data from imported Task");
              case "template":
                return __("Create a new Datastore and load data from Task template");
              case "version":
                return __("Create a new Datastore and load data from Task version");
              default:
                return __("Create a new Datastore and load data");
            }
            /* eslint-enable */
          },
          value: "CREATE_DATASTORE_LOAD_DATA",
          disabled: function(emptyDataDump) {
            return emptyDataDump;
          }
        },
        {
          label: function(createType, emptyDataDump) {
            let defaultPreText = emptyDataDump
              ? __("Create a new Datastore")
              : __("Create a new Datastore but ignore data");
            /* eslint-disable */
            switch (createType) {
              case "import":
                return __(":defaultPreText from imported Task", { defaultPreText });
              case "template":
                return __(":defaultPreText from Task template", { defaultPreText });
              case "version":
                return __(":defaultPreText from Task version", { defaultPreText });
              default:
                return defaultPreText;
            }
            /* eslint-enable */
          },
          value: "CREATE_DATASTORE_EMPTY_DATA",
          disabled: function() {
            return false;
          }
        }
      ],
      secureVariableOptions: [
        {
          label: function(createType) {
            /* eslint-disable */
            switch (createType) {
              case "import":
                return __("Use a matching secure variable (if available) and rewrite values from imported Task");
              case "template":
                return __("Use a matching secure variable (if available) and rewrite values from Task template");
              case "version":
                return __("Use a matching secure variable (if available) and rewrite values from Task version");
              default:
                return __("Use a matching secure variable (if available) and rewrite values");
            }
            /* eslint-enable */
          },
          value: "REUSE_SECURE_VARIABLE_REPLACE_VALUE",
          disabled: function(loadConfig) {
            return _.get(loadConfig, "locked_secure_variables", false);
          }
        },
        {
          label: function(createType) {
            /* eslint-disable */
            switch (createType) {
              case "import":
                return __("Use a matching secure variable (if available) but ignore values from imported Task");
              case "template":
                return __("Use a matching secure variable (if available) but ignore values from Task template");
              case "version":
                return __("Use a matching secure variable (if available) but ignore values from Task version");
              default:
                return __("Use a matching secure variable (if available) but ignore values");
            }
            /* eslint-enable */
          },
          value: "REUSE_SECURE_VARIABLE_REUSE_VALUE",
          disabled: function() {
            return false;
          }
        },
        {
          label: function(createType) {
            /* eslint-disable */
            switch (createType) {
              case "import":
                return __("Create a new secure variable with values from imported Task");
              case "template":
                return __("Create a new secure variable with values from Task template");
              case "version":
                return __("Create a new secure variable with values from Task version");
              default:
                return __("Create a new secure variable with values");
            }
            /* eslint-enable */
          },
          value: "CREATE_SECURE_VARIABLE_LOAD_VALUE",
          disabled: function(loadConfig) {
            return _.get(loadConfig, "locked_secure_variables", false);
          }
        },
        {
          label: function() {
            return __("Create a new secure variable with empty values");
          },
          value: "CREATE_SECURE_VARIABLE_EMPTY_VALUE",
          disabled: function() {
            return false;
          }
        }
      ],
      reusePromptOptions: [
        {
          label: __("Use a matching Prompt (if available)"),
          value: true
        },
        {
          label: __("Create a new Prompt"),
          value: false
        }
      ]
    };
  },
  computed: {
    ...mapState("app", {
      selectedAccountId: state => state.selectedAccountId
    }),

    knowledgeGroupErrorFnConfig() {
      return {
        fn: this.emptyMap,
        type: "knowledge_groups"
      };
    },

    hasToConfigureSecureVariablesDuringImport() {
      return this.loadConfig.has_secure_variables;
    },

    getReplaceLinkTaskWarning() {
      return __(
        "This may modify other Tasks in your account that are currently linked to the selected Task"
      );
    },

    movingToDifferentAccount() {
      return _.get(this.loadConfig, "moving_to_different_account", true);
    },

    authProfileIdsExportedWithCredentials() {
      return _(
        _.get(this.loadConfig, "auth_profiles_with_credentials_attached", [])
      )
        .map(authProfile => {
          return authProfile.auth_profile_id;
        })
        .value();
    },

    isAuthProfileExportedWithCredentials() {
      return id => this.authProfileIdsExportedWithCredentials.includes(id);
    },

    emptyDataDump() {
      return _.isEmpty(this.dataStoresDumpedWithData);
    },

    dataStoresDumpedWithData() {
      return _.get(this.loadConfig, "data_stores_with_data", []);
    },

    getNoDataDumpWarning() {
      /* eslint-disable */
      switch (this.createType) {
        case "template":
          return __("Task Template's Datastores contains no data");
        case "import":
          return __("Imported Task's Datastores contains no data");
        case "version":
          return __("Task Version's Datastores contains no data");
        default:
          return __("no dependency on Datastores");
      }
      /* eslint-enable */
    },

    getLabelForReplacementTask() {
      switch (this.createType) {
        case "template":
          return __("Redirect currently linked Tasks to new Task?");
        case "import":
          return __("Redirect currently linked Tasks to imported Task?");
        default:
          return "";
      }
    },

    getNoSecureVariableValueWarning() {
      /* eslint-disable */
      switch (this.createType) {
        case "template":
          return __("Task template's secure variables contain no attached values");
        case "import":
          return __("Secure variables for the Task being imported contain no attached values");
        case "version":
          return __("Task version's secure variables contain no attached values");
        default:
          return __("no dependency on secure variables");
      }
      /* eslint-enable */
    },

    newContentSuffixPlaceHolderText() {
      return __("Enter custom text");
    },

    newContentPrefixWarningText() {
      if (!this.isVersionSwitch) {
        // eslint-disable-next-line
        return __(
          "The text entered will be prepended to any content items to resolve duplicate name conflicts. Leaving this blank will automatically prepend the first six (6) characters from the new Task name."
        );
      }

      // eslint-disable-next-line
      return __(
        "The text entered will be prepended to any content items to resolve duplicate name conflicts. Leaving this blank will automatically prepend the first six (6) characters from the target Version name."
      );
    },

    ttsVoicesRequiringReplacements() {
      return _.get(this.configOptions, "voices", {});
    },

    asrLanguagesRequiringReplacements() {
      return _.get(this.configOptions, "asr_languages", {});
    },

    linkNodesRequiringNewTargets() {
      return _.map(_.get(this.configOptions, "link_nodes", []), item => {
        item.replacement_options = this.targetTasks;
        return item;
      });
    },

    targetTasks() {
      return _.get(this.configOptions, "taskOptions", []);
    },

    hasTargetTasks() {
      return !_.isEmpty(this.targetTasks);
    },

    authProfilesRequiringNewMapping() {
      return _.get(this.configOptions, "auth_profiles", []);
    },

    knowledgeGroupRequiresNewMapping() {
      return _.get(this.configOptions, "knowledge_group", []);
    },

    availableTargetTaskIds() {
      return _.map(this.targetTasks, task => task.task_id);
    },

    replacementsRequired() {
      return (
        !this.empty(this.ttsVoicesRequiringReplacements) ||
        !this.empty(this.asrLanguagesRequiringReplacements) ||
        !this.empty(this.linkNodesRequiringNewTargets) ||
        !this.empty(this.authProfilesRequiringNewMapping) ||
        !this.empty(this.knowledgeGroupRequiresNewMapping)
      );
    },

    emptyMap() {
      return type => {
        return val => {
          return !_.get(
            this.configuration.replacement_mapper,
            type + "." + val
          );
        };
      };
    },
    versionSwitchLan() {
      return this.isVersionSwitch
        ? __("target version")
        : this.createType === "template"
        ? __("template")
        : __("imported task");
    },

    /**
     * Message for the auth profile selector placeholder
     * @returns {String} Translated message for the auth profile selector placeholder
     */
    authProfileSelectorPlaceholder() {
      // eslint-disable-next-line
      return __("If a Profile is not selected a new Authentication will be automatically created");
    },

    /**
     * Content for the link node selector placeholder
     * @returns {String} Translated content for the link node selector placeholder
     */
    linkNodeSelectorPlaceholder() {
      // eslint-disable-next-line
      return __("If a Task is not selected Linked Task node will be automatically converted into a Comment node");
    },

    validSecureVariableOptions() {
      return _.filter(
        this.secureVariableOptions,
        option => !option.disabled(this.loadConfig)
      );
    },

    validDatastoreOptions() {
      return _.filter(
        this.dataStoreOptions,
        option => !option.disabled(this.emptyDataDump)
      );
    },

    getAdditionalOptionsHeading() {
      return __("Additional Options");
    },

    showManagePromptsOption() {
      return this.loadConfig.has_prompts && this.createType === "import";
    }
  },
  props: {
    configOptions: {
      type: Object,
      required: true
    },
    loadConfig: {
      type: Object,
      required: true
    },
    task: {
      type: Object,
      required: false
    },
    filePath: {
      type: String,
      required: false,
      default: ""
    },
    createType: {
      type: String,
      required: true
    },
    template: {
      type: Object,
      required: false
    },
    isVersionSwitch: {
      required: false,
      type: Boolean,
      default: false
    },
    knowledgeGroupItemConfig: {
      type: Object,
      required: false,
      default: () => ({
        keyProp: "knowledgeGroupId",
        valueProp: "knowledgeGroupId",
        labelProp: "knowledgeGroupName",
        heading: "Ai Knowledge",
        title: "Knowledge Group"
      })
    },
    knowledgeGroupLineItemOptionConfig: {
      type: Object,
      required: false,
      default: () => ({
        value: "knowledgeGroupId",
        label: "name",
        title: "Knowledge Group",
        placeholder: "Select Knowledge Group"
      })
    },
    replacementOptions: {
      type: Object,
      required: false,
      default: () => ({
        prop: "replacement_options"
      })
    }
  },
  methods: {
    ...mapActions("tasks", {
      createTask: "createTask"
    }),
    empty(val) {
      if (_.isObject(val) || _.isArray(val)) {
        return _.isEmpty(val);
      }
      return !val;
    },

    submitForm() {
      this.$refs.configTaskCreateForm.validate(valid => {
        if (valid) {
          this.isSubmitting = true;
          let isSync = this.createType === "blank";
          let task = _.cloneDeep(this.task);
          task.ac_id = this.selectedAccountId;
          task.create_type = this.createType;
          task.template_id = this.template ? this.template.template_id : -1;
          task.import_config = this.configuration.import_config;
          task.replacement_mapper = this.configuration.replacement_mapper;
          task.new_content_suffix = this.configuration.new_content_suffix;

          task.file_path = this.filePath;
          this.createTask({ task, async: !isSync })
            .then(data => {
              this.isSubmitting = false;
              if (isSync) {
                this.$message({
                  type: "success",
                  message: __("Task added Successfully")
                });
              }
              this.$emit(
                "created",
                data.data.task_id,
                data.request_id,
                !data.request_id
              );
            })
            .catch(err => {
              console.error(err);
              this.isSubmitting = false;
              this.$message({
                type: "error",
                message: err.message
              });
            });
        } else {
          return false;
        }
      });
    }
  },
  watch: {
    loadConfig: {
      immediate: true,
      handler(val) {
        if (_.get(val, "has_data_stores", false)) {
          this.$set(this.configuration, "dataStores", "");
        }
        if (_.get(val, "has_secure_variables", false)) {
          this.$set(this.configuration, "secureVariables", "");
        }
        this.$set(this.configuration, "replaceLinkedTasks", []);
      }
    },

    configOptions: {
      immediate: true,
      handler: function() {
        // initialize asr language options
        _.each(this.asrLanguagesRequiringReplacements, asrLanguage => {
          this.$set(
            this.configuration.replacement_mapper.asr_languages,
            asrLanguage.language_id,
            _.get(asrLanguage, "replacement_options.0.language_id")
          );
        });

        // initialize tts voices option
        _.each(this.ttsVoicesRequiringReplacements, ttsVoice => {
          let firstKey = _.get(
            _.keys(ttsVoice.replacement_options),
            0,
            "unknown"
          );
          this.$set(
            this.configuration.replacement_mapper.voices,
            ttsVoice.tts_voice_id,
            _.get(ttsVoice, `replacement_options.${firstKey}.0.tts_voice_id`)
          );
        });

        // initialize link node options
        _.each(this.linkNodesRequiringNewTargets, linkNode => {
          let targetTaskId = "";
          if (!this.movingToDifferentAccount) {
            let originalTargetTaskId = _.get(linkNode, "target_task_id");
            if (_.includes(this.availableTargetTaskIds, originalTargetTaskId)) {
              targetTaskId = originalTargetTaskId;
            }
          }
          this.$set(
            this.configuration.replacement_mapper.link_nodes,
            linkNode.node_id,
            targetTaskId
          );
        });

        // initialize link node options
        _.each(this.authProfilesRequiringNewMapping, authProfile => {
          this.$set(
            this.configuration.replacement_mapper.auth_profiles,
            authProfile.auth_profile_id,
            ""
          );
        });

        _.each(this.knowledgeGroupRequiresNewMapping, knowledgeGroup => {
          this.$set(
            this.configuration.replacement_mapper.knowledge_groups,
            knowledgeGroup.knowledgeGroupId,
            ""
          );
        });
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import "~@/styles/element-variables.scss";
@import "~@/styles/content-edit-info.scss";
@import "~@/styles/tags.scss";

.import-options-form-container {
  .flex {
    display: flex;
    flex: 1;
  }

  .sub-script-info {
    line-height: 1.4;
    font-size: 0.8rem;
    padding-block: 5px;
    color: #777777;
    word-break: break-word;
  }

  ::v-deep .no-red-highlight .el-input__inner,
  ::v-deep .no-red-highlight .el-textarea__inner,
  ::v-deep .empty-row .el-input__inner,
  ::v-deep .empty-row .el-textarea__inner,
  ::v-deep .non-empty-val .el-input__inner,
  ::v-deep .non-empty-val .el-textarea__inner {
    border-color: $--border-color-base !important;

    &:focus {
      border-color: $--color-text-regular !important;
    }
  }

  .datastore-name-tag {
    height: 28px;
    line-height: 26px;
    font-size: 12px;
    margin: 5px;
  }

  .manage-data-stores,
  .manage-secure-variables,
  .manage-prompts,
  .replace-task-with {
    word-break: break-word;
    .info {
      color: #696969;
      display: flex;
      align-items: center;
      line-height: 1.5;
      margin-top: 5px;
      font-size: 0.8125rem;
      letter-spacing: 0.005rem;
    }
  }
}
</style>

<style lang="scss">
.auth-profile-options {
  .ok {
    color: green;
  }

  .error {
    color: firebrick;
  }
}
</style>
